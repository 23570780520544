import Typography from "typography"
import Wordpress2016 from "typography-theme-wordpress-2016"
import { MOBILE_MEDIA_QUERY } from "typography-breakpoint-constants"

const typography = new Typography({
  ...Wordpress2016,
  headerFontFamily: [
    'Dancing Script',
    ...Wordpress2016.headerFontFamily
  ],
  googleFonts: [
    ...Wordpress2016.googleFonts,
    // {
    //   name: 'Amarante',
    //   styles: ['400']
    // },
    {
      name: 'Dancing Script',
      styles: ['400']
    },
    {
      name: 'Miss Fajardose',
      styles: [
        '400'
      ]
    },
  ],
  baseFontSize: '20px',
  scaleRatio: 7 / 2,
  overrideThemeStyles: (/*{ rythm }, options, styles*/) => {
    return {
      "body": {
        // background: 'black'
      },
      "a.gatsby-resp-image-link": {
        boxShadow: `none`,
      },
      "h1,h2,h3,h4,h5,h6": {
        fontFamily: 'Dancing Script',
        fontWeight: 'normal',
        textTransform: 'initial'
      },
      "blockquote": {
        fontFamily: 'Dancing Script',
        fontWeight: 'normal',
        borderLeft: '0.1rem solid hsla(0,0%,0%,0.9)',
        paddingLeft: '1.65001rem',
        fontSize: '1.54169rem',
      },
      "a": {
        boxShadow: 'none',
        color: '#89120a',
      },
      "a:hover, a:focus": {
        boxShadow: '0 1px 0 0 currentColor',
      },
      "a.underlineLink:hover, a.underlineLink:focus": {
        boxShadow: 'none',
        textDecoration: 'underline',
      },
      "mark,ins": {
        color: '#89120a',
      },
      [MOBILE_MEDIA_QUERY]: {
        "blockquote": {
          marginLeft: '0'
        },
      },
    }
  }
})

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
