import React, { CSSProperties } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'

import Bio from "../components/bio"
import { rhythm, scale } from "../utils/typography"

type LayoutOptions = {
  title: string,
  children?,
  style?: "home" | undefined,
  location?
}

const Layout = ({ title, children, style }: LayoutOptions) => {
  let header

  const data = useStaticQuery(graphql`
    query LayoutQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      headerBackground: file(absolutePath: { regex: "/header-background.jpg/" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      site {
        siteMetadata {
          title
          author {
            name
            summary
            email
          }
          social {
            instagram
          }
          contactEmail        
          siteConfiguration {
            useCompactHeaderInArticles
          }
        }
      }
    }
  `)

  const { author, contactEmail, siteConfiguration } = data.site.siteMetadata
  const { useCompactHeaderInArticles } = siteConfiguration
  const imageData = data.headerBackground.childImageSharp.fluid

  const wrapperStyle: CSSProperties = {
    marginLeft: `auto`,
    marginRight: `auto`,
    maxWidth: rhythm(24),
    padding: `0 ${rhythm(3 / 4)}`,
  }

  if (!useCompactHeaderInArticles || style === "home") {
    header = (
      <div
        style={{
          ...wrapperStyle,
          paddingTop: rhythm(1),
          paddingBottom: rhythm(3 / 4),
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          <h1
            style={{
              ...scale(1.2),
              marginBottom: 0,
              marginTop: 0,
              fontFamily: 'Miss Fajardose'
            }}
          >
            {title}
          </h1>
        </Link>
        <Bio />
      </div>
    )
  } else {
    header = (
      <div
        style={{
          ...wrapperStyle,
          paddingTop: rhythm(3 / 4),
          paddingBottom: rhythm(3 / 4),
        }}
      >
        <h3
          style={{
            ...scale(1),
            fontFamily: `Miss Fajardose`,
            marginTop: 0,
            marginBottom: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            <Image
              fixed={data.avatar.childImageSharp.fixed}
              alt={author.name}
              style={{
                marginRight: rhythm(1 / 2),
                marginBottom: 0,
                minWidth: 50,
                borderRadius: `100%`,
              }}
              imgStyle={{
                borderRadius: `50%`,
              }}
            />
            {title}
          </Link>
        </h3>
      </div>
    )
  }

  const footNotesStyles: CSSProperties = {
    display: 'block',
    fontSize: '.6em',
    marginTop: rhythm(.2),
  }

  const footNotesLinks: CSSProperties = {
    color: "white",
    fontWeight: "bold",
    textDecoration: "underline",
    boxShadow: `none`,
  }

  return (
    <>
      <header
        style={{
          background: '#333',
          color: 'white',
          textShadow: 'rgb(0, 0, 0) 0 0 5px, rgb(0, 0, 0) 0px 0px 20px',
        }}
      >
        <BackgroundImage fluid={imageData}>
          {header}
        </BackgroundImage>
      </header>
      <main
        style={{
          ...wrapperStyle,
          paddingTop: rhythm(3 / 4),
          paddingBottom: rhythm(2),
        }}
      >
        {children}
      </main>
      <footer
        style={{
          background: '#333',
          color: 'white',
        }}
      >
        <div
          style={{
            ...wrapperStyle,
            paddingTop: rhythm(1.5),
            paddingBottom: rhythm(1.5),
          }}
        >
          <div>
            Dama di libri © {new Date().getFullYear()}
          </div>
          <small
            style={{
              ...footNotesStyles,
              marginTop: rhythm(.4),
            }}
          >
            Un blog a cura di Silvia Marchetti
          </small>
          <small style={footNotesStyles}>
            Built with
            {` `}
            <span style={{ color: 'red' }}>❤</span>
            {` `}
            by LL Software
          </small>
          <small
            style={{
              ...footNotesStyles,
              marginTop: rhythm(.4),
            }}
          >
            Tutti i diritti sono riservati.<br />
          È vietato qualsiasi utilizzo, totale o parziale, dei contenuti inseriti nel sito, ivi inclusa la memorizzazione, riproduzione, rielaborazione, diffusione o distribuzione dei contenuti stessi mediante qualunque piattaforma tecnologica, supporto o rete telematica, senza l'autorizzazione degli autori.<br />
          Le citazioni o le riproduzioni di brani di opere effettuate nel presente sito Internet hanno esclusivo scopo di critica, discussione e ricerca nei limiti stabiliti dall'art. 70 della Legge 633/1941 sul diritto d'autore, e recano menzione della fonte, del titolo delle opere, dei nomi degli autori e degli altri titolari di diritti, qualora tali indicazioni figurino sull'opera riprodotta.<br />
          Per eventuali rettifiche e per segnalazioni si prega di inviare una e-mail all'indirizzo <a href={`mailto:${contactEmail}`} style={footNotesLinks}>{contactEmail}</a>
          </small>
          <small
            style={{
              ...footNotesStyles,
              marginTop: rhythm(.4),
            }}
          >
            La foto della copertina è di <a target="_blank" rel="noopener noreferrer" href="https://pixabay.com/it/users/Myriams-Fotos-1627417/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=4888559" style={footNotesLinks}>Myriam Zilles</a> da <a target="_blank" rel="noopener noreferrer" href="https://pixabay.com/it/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=4888559" style={footNotesLinks}>Pixabay</a>
          </small>
        </div>
      </footer>
    </>
  )
}

export default Layout
